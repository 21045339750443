<pboxc-dialog-container
  *ngIf="cartService.cart$ | async as cart"
  class="dialog"
  [hasCloseButton]="true"
>
  <header class="dialog__header header">
    <div class="header__top">
      <h2 class="h1">Your Cart</h2>
      <span class="h1 header__price">{{
        cartTotalPrice$ | async | currency
      }}</span>
    </div>

    <pboxc-free-delivery-message [cart]="cart"></pboxc-free-delivery-message>
  </header>

  <section class="dialog__cart cart">
    <div class="cart__top">
      <h2>{{ title$ | async }}</h2>
      <pboxc-loader
        [isLoading]="(isCartLoading$ | async) === true"
        [width]="20"
        [height]="20"
      ></pboxc-loader>
    </div>

    <ul *ngIf="cartItems$ | async as cartItems">
      <li
        *ngFor="let cartItem of cartItems; trackBy: trackCartItem"
        class="cart-item"
      >
        <span class="cart-item__name">{{ cartItem.product.name }}</span>
        <pboxc-cart-item-price
          [item]="cartItem"
          class="cart-item__price"
        ></pboxc-cart-item-price>
        <pboxc-cart-item-quantity [item]="cartItem"></pboxc-cart-item-quantity>
      </li>

      <li *ngIf="cartItems.length === 0" class="cart-item">
        <strong>Your cart is empty.</strong>
      </li>
    </ul>
  </section>

  <footer class="footer">
    <ng-content></ng-content>

    <div class="footer__actions actions">
      <a
        *ngIf="isCheckoutAvailable$ | async; else checkoutDisabledTemplate"
        class="primary primary_bigger actions__button"
        routerLink="order/cart"
        mat-dialog-close
      >
        Check Out
      </a>
      <button
        type="button"
        class="primary primary_bigger actions__button"
        mat-dialog-close
      >
        Continue Shopping
      </button>
    </div>

    <ng-template #checkoutDisabledTemplate>
      <button
        type="button"
        [disabled]="(isCheckoutAvailable$ | async) === false"
        class="primary primary_bigger actions__button"
      >
        Check Out
      </button>
    </ng-template>
  </footer>
</pboxc-dialog-container>
